<template>
  <div class="main">
    <van-nav-bar
        title="编辑资料"
        left-text="取消"
        :border="false"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-button style="margin-top: 5px" color="#0066ff" round type="info" @click="submit" size="small">
          保存
        </van-button>
      </template>
    </van-nav-bar>
    <div style="padding: 5px 20px">
      <div>
        <div class="top-row">
          <van-uploader :after-read="afterRead" v-if="avatar">
            <img
                class="avatar"
                width="80"
                height="80"
                v-lazyload
                :key="avatarKey"
                :data-src="avatar"
            />
          </van-uploader>
          <div class="top-right">
            <div class="top-right-item">
              <p>获赞</p>
              <p class="color-gray">{{info.zan}}</p>
            </div>
            <div class="top-right-item">
              <p>被关注</p>
              <p class="color-gray">{{info.beConcerned}}</p>
            </div>
            <div class="top-right-item">
              <p>关注</p>
              <p class="color-gray">{{info.concerned}}</p>
            </div>
          </div>
        </div>
        <div class="basic-wrap">
          <h3 style="padding-left: 16px">基本资料</h3>
          <van-field v-model="editForm.nickName" :value="user.nickName" label="昵称" />
          <van-field name="radio" label="回复人性别">
            <template #input>
              <van-radio-group v-model="editForm.sex" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
                <van-radio name="0">保密</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field type="textarea" rows="2" maxlength="50" v-model="editForm.introduction"  label="简介" />
          <van-popup v-model="show" round position="top" :style="{ height: '220px' }">
            <div style="padding: 20px">
              <van-tag round
                       @click=""
                       v-for="(item, index) in tagList" :key="index"
                       size="large"
                       plain type="primary"
                       style="margin-bottom: 10px;margin-right: 10px">
                {{ item.title }}
              </van-tag>
            </div>
          </van-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FindAllType, saveArticle} from "@/api/article";
import {uploadImg, getPostForm, editUser, getUserTotal} from "@/api/user";
import axios from "axios";
import {SaveVideo} from "@/api/video";
import {checkArticleForm, checkVideoForm} from "@/utils/check";
import {BASE_RUL} from "@/utils/request";
import * as Minio from 'minio';
import {Toast} from 'vant';



export default {
  name: "Upload",

  data() {
    return {
      tag: '',
      posterFile: [],
      fileList: [{url: ''}],
      tagList: [],
      show: false,
      formData:{},
      editForm:{
        nickName:'',
        sex:'1',
        introduction:'',
        headUrl:''
      },
      avatar:'',
      avatarKey:new Date().getTime(),
      info:{}
    }
  },
  created() {
    getUserTotal({}).then((res)=>{
      if (res.code==0) {
        this.info=res.result
        console.log(res.result,'res.result')
      }
    })
  },

  mounted() {
    getPostForm({}).then((res)=>{
      if (res.code==0)
      {
        this.formData=res.result
        this.tagList=res.result.jobDataType
        this.columns=res.result.categoryId.map((item)=>{
          return {
            text:item.title,
            value:item.id
          }
        })
        this.columns2=res.result.jobDataType.map((item)=>{
          return {
            text:item.title,
            value:item.value
          }
        })
      }
    })
  },
  computed:{
    user:function () {
      this.editForm.nickName=this.$store.state.userInfo.nickName
      this.editForm.sex=this.$store.state.userInfo.sex+''
      this.editForm.introduction=this.$store.state.userInfo.introduction
      this.avatar=this.$store.state.userInfo.headUrl
      this.avatarKey=new Date().getTime()
      return this.$store.state.userInfo||{}
    }
  },

  methods: {

    submit() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      editUser({...this.editForm}).then((res)=>{
        if (res.code==0)
        {

        }
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    afterRead(file){
      // 此时可以自行将文件上传至服务器
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
      });
      uploadImg({file:file}).then((res)=>{
        if (res.success)
        {
          this.avatar=res.result[0];
          this.editForm.headUrl=res.result[0];
          this.submit()
          this.$store.dispatch('updateUserInfo')
        }
        else {

        }
      })
    }
  }

}
</script>

<style scoped>

.poster-btn {
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  width: 100px;
  height: 50px;
  position: absolute;
  z-index: 99;
  bottom: 15px;
  color: #0066ff;
  right: 3vw;
  line-height: 27px;
}

.poster-btn-img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.in-title {
  color: rgba(0, 0, 0, .7);
  letter-spacing: 1px;
  width: 97%;
  margin: auto;
  height: 50px;
  border: none;
  font-size: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.in-content {
  width: 97%;
  margin-top: 20px;
  font-size: 15px;
  color: rgba(0, 0, 0, .5);
  letter-spacing: 1px;
  border: none;
  line-height: 25px;
}

.footer {
  position: absolute;
  border-bottom: 0;
  font-weight: bold;
  font-size: 14px;
  color: #0066ff;
  padding-top: 4px;
}
.top-row{
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 15px;
}
.top-right{
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.top-right p{
  text-align: center;
}
.color-gray{
  color: #ababab;
}
.avatar{
  border-radius: 40px;
}
.main{
  min-height: 100vh;
  background: #f5f6f7 !important;
}
.basic-wrap{
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
